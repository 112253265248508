import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from "@material-ui/core/Typography";
import $ from "jquery";
import Box from "@material-ui/core/Box";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function renderRow(props) {
    const { data, index, style } = props;

    const nameUser = data[index]['first_name'] + ' ' + (data[index]['last_name'] ? data[index]['last_name'][0] + '.' : '');
    return (
        <ListItem button style={style} key={index}>

            <ListItemText primary={<Typography style={{ fontSize: '10pt' }}>{index + 1}</Typography>} />
            <Grid container item xs={12} spacing={4}>
            <ListItemAvatar style={{ marginLeft: '30px' }}>
                <Avatar style={{ height: '35px', width: '35px' }} alt="" src={data[index]['photo_url']} />
            </ListItemAvatar>
                <ListItemText primary={<Typography style={{ fontSize: '10pt' }}>{nameUser}</Typography>} />
            </Grid>
            <Box>
                <ListItemText primary={<Typography style={{ fontSize: '10pt', fontWeight: 500 }}>{data[index]['score']}К</Typography>} />
            </Box>

            <Avatar style={{ height: '28px', width: '28px' }} alt="" src="https://fps-test.com/bunny_fps/menu/imgs/bunny3.png" />
        </ListItem>
    );
}

renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
}

export default function VirtualizedList(sizeFrame) {

    const classes = useStyles();
    const heightWindow = Number(sizeFrame.height);

    const [dataTop, setDataTop] = useState([]);

    const getTop100 = () => {
        console.log('Обычный ТОП 100');
        $.ajax({
            url: 'https://fps-test.com/bunny_fps/game/php/getTop100.php',
            type: 'post',
            data: {
                //score: score,
            },
            success: function (response) {
                const dataJson = JSON.parse(response); //Записываем данные топа
                setDataTop(dataJson);

                console.log('dataJson = ', dataJson);

            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.log(textStatus, errorThrown);
            }
        });
    };

    useMemo(
        () => getTop100(),
        []
    );

    return (
        <div className={classes.root}>
            <FixedSizeList height={heightWindow} width={'100%'} itemSize={50} itemCount={dataTop.length} itemData={dataTop}>
                {renderRow}
            </FixedSizeList>
        </div>
    );
}
